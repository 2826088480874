import cx from 'clsx';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import Notification from '@placekit/uikit/components/Notification';
import { ThemeProvider } from '@placekit/uikit/features/ThemeContext';

export const UIContext = createContext({
  notify: () => {},
});
export const useUIContext = () => useContext(UIContext);

export const UIProvider = (props) => {
  const [notif, setNotif] = useState({});

  // dismiss notification
  const dismiss = useCallback(() => {
    if (notif?.onDismiss?.call) {
      notif.onDismiss();
    }
    setNotif({});
  }, [notif]);

  // schedule dismiss
  useEffect(() => {
    if (!notif.timestamp) return;
    const remaining = Math.max(0, notif.timestamp + notif.delay - Date.now());
    const timeout = setTimeout(dismiss, remaining);
    return () => {
      clearTimeout(timeout);
    };
  }, [dismiss, notif]);

  // attach timestamp, sanitize delay and save notification
  const notify = useCallback(
    (notif) =>
      setNotif({
        ...notif,
        delay: Number.isInteger(notif.delay) ? Math.max(0, notif.delay) : 3200,
        timestamp: Date.now(),
      }),
    [],
  );

  const { timestamp, delay, ...notifProps } = notif;

  // Return context values
  // ----------------------------------------
  return (
    <ThemeProvider theme="light" roundedButtons={true} linkComponent={Link}>
      <UIContext.Provider
        value={{
          notify,
        }}
      >
        {props.children}
        <div
          aria-live="assertive"
          className="fixed z-40 inset-x-0 bottom-0 flex flex-col items-end justify-end sm:justify-start p-4 lg:px-8 lg:py-6 space-y-2 pointer-events-none"
        >
          {!!timestamp && (
            <Notification
              key={timestamp?.toString()}
              {...notifProps}
              onDismiss={dismiss}
              className={cx('pointer-events-auto animate-fade-up-in', notifProps.className)}
            />
          )}
        </div>
      </UIContext.Provider>
    </ThemeProvider>
  );
};

UIProvider.propTypes = {
  children: PropTypes.node,
};
