import { Transition } from '@headlessui/react';
import cx from 'clsx';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import Button from '@placekit/uikit/components/Button';
import Icon from '@placekit/uikit/components/Icon';
import Link from '@placekit/uikit/components/Link';
import Logo from '@placekit/uikit/components/Logo';
import { useScrollReveal } from '@placekit/uikit/features/useScrollReveal';
import { useClickOutside } from '@placekit/uikit/features/useClickOutside';

import Container from 'components/Container';
import Footer from 'components/Footer';

const navLinks = [
  { label: 'Features', href: '/#features', scroll: false },
  { label: 'Pricing', href: '/#pricing', scroll: false },
  { label: 'Developers', href: '/developers' },
  { label: 'Blog', href: '/blog' },
];

const mobileLinks = [
  ...navLinks,
  { label: 'Terms', href: '/terms' },
  { label: 'About', href: '/about' },
  { label: 'Contact', href: '/about#contact', scroll: false },
  { label: 'Services status', href: 'https://placekit.statuspage.io', target: '_blank' },
];

const MainShell = (props) => {
  const [navOpen, setNavOpen] = useState(false);
  const clickOutsideRef = useClickOutside(() => setNavOpen(false));

  // close nav when route changes (link is clicked)
  const router = useRouter();

  useEffect(() => {
    const onRouteChange = () => {
      setNavOpen(false);
    };
    router.events.on('routeChangeStart', onRouteChange);
    router.events.on('hashChangeStart', onRouteChange);
    return () => {
      router.events.off('routeChangeStart', onRouteChange);
      router.events.off('hashChangeStart', onRouteChange);
    };
  }, [router]);

  // set scroll reveal feature for header
  const scrollReveal = useScrollReveal({
    showDelay: 100,
    hideDelay: 300,
    threshold: 80,
  });

  const canonical = useMemo(() => {
    const output = new URL(router.asPath, process.env.NEXT_PUBLIC_BASE_URL);
    output.search = '';
    return output.href.replace(/\/$/, '');
  }, [router]);

  return (
    <>
      <Head>
        <title>{[props.meta?.title, 'PlaceKit'].join(' | ')}</title>
        <link rel="canonical" href={canonical} />
        {!!props.meta?.description && <meta name="description" content={props.meta.description} />}
        <meta
          property="og:title"
          content={[props.meta?.title, 'PlaceKit'].filter((s) => !!s).join(' | ')}
        />
        {!!props.meta?.description && (
          <meta property="og:description" content={props.meta.description} />
        )}
        <meta property="og:type" content={props.meta?.type || 'website'} />
        <meta
          property="og:image"
          content={
            props.meta?.image || `${process.env.NEXT_PUBLIC_BASE_URL}/placekit-opengraph.png`
          }
        />
        <meta property="og:image:alt" content={props.meta?.imageAlt || 'PlaceKit logo'} />
      </Head>

      <header
        ref={clickOutsideRef}
        className={cx(
          'fixed top-0 inset-x-0 z-30 max-h-screen overflow-y-auto',
          'bg-gray-50/80',
          'transition-all ease-out duration-300 transform-gpu',
          {
            'backdrop-blur-lg border-b border-gray-300/40': !scrollReveal.isAtOrigin || navOpen,
            '-translate-y-full md:translate-y-0': scrollReveal.isHidden && !navOpen,
          },
        )}
      >
        <Container
          className={cx(
            'flex items-center justify-between h-20 gap-x-2',
            'transition-all ease-out duration-300 transform-gpu',
            {
              'md:h-40': scrollReveal.isAtOrigin,
            },
          )}
        >
          <Link
            href="/"
            className="flex items-center space-x-0.5 sm:space-x-2 relative sm:mr-6 xl:mr-16 text-accent-500"
            aria-label="PlaceKit home"
          >
            <Logo full={true} size="medium" className="sm:h-8" />
          </Link>
          {props.nav === 'full' && (
            <nav className="grow hidden md:flex gap-x-6 xl:gap-x-10">
              {navLinks.map(({ label, ...linkProps }, i) => (
                <Link
                  key={i.toString()}
                  {...linkProps}
                  className="text-gray-500 hover:text-accent-600 focus:text-accent-600 hover:underline focus:underline underline-offset-2 decoration-2 decoration-accent-100"
                >
                  {label}
                </Link>
              ))}
            </nav>
          )}
          <div className="flex items-center gap-x-2 sm:gap-x-4">
            {props.nav === 'full' && (
              <Button
                href="https://app.placekit.io/auth/signin"
                label="Sign in"
                kind="none"
                className="hidden md:inline-block text-gray-500 hover:bg-accent-50 hover:text-accent-600 focus:bg-accent-50 focus:text-accent-600"
              />
            )}
            <Button
              href="https://app.placekit.io/auth/register"
              label="Get started"
              kind="primary"
            />
            {props.nav === 'full' && (
              <Button
                label="Toggle Navigation"
                onClick={() => setNavOpen((prev) => !prev)}
                iconOnly={true}
                icon={navOpen ? 'x-mark' : 'bars-3'}
                kind="none"
                className="relative md:hidden z-50 text-gray-500 focus:text-gray-900 active:ring-0 focus:ring-0 active:ring-offset-0 focus:ring-offset-0"
              />
            )}
          </div>
        </Container>
        {props.nav === 'full' && (
          <Transition
            show={navOpen}
            enter="transition ease-in-out duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4"
            className={cx('flex-col gap-y-4 p-4 sm:px-6 border-t border-gray-300/40', {
              hidden: !navOpen,
              'flex md:hidden': navOpen,
            })}
          >
            {mobileLinks.map(({ label, ...linkProps }, i) => (
              <Link
                key={i.toString()}
                {...linkProps}
                className="flex items-center justify-between text-gray-600 hover:text-accent-600 focus:text-accent-600"
              >
                <span>{label}</span>
                {linkProps.target === '_blank' && (
                  <Icon name="arrow-top-right-on-square" size="small" className="opacity-80" />
                )}
              </Link>
            ))}
            <hr className="border-gray-300/20" />
            <Link
              href="https://app.placekit.io"
              className="text-gray-600 hover:text-accent-600 focus:text-accent-600"
            >
              Sign in
            </Link>
          </Transition>
        )}
      </header>

      <div className="w-full min-h-screen flex flex-col pt-20 md:pt-40">
        <main className="relative grow items-center">{props.children}</main>
        <Footer />
      </div>
    </>
  );
};

MainShell.defaultProps = {
  meta: {},
  nav: 'full',
};

MainShell.propTypes = {
  /** Page meta */
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  /** Navigation style */
  nav: PropTypes.oneOf(['full', 'compact']),
  /** Content */
  children: PropTypes.node,
};

export default MainShell;
