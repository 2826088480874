import Link from '@placekit/uikit/components/Link';
import Logo from '@placekit/uikit/components/Logo';
import SocialIcon from '@placekit/uikit/components/SocialIcon';

import Container from 'components/Container';

const sections = [
  {
    title: 'Product',
    links: [
      { label: 'Overview', href: '/' },
      { label: 'Features', href: '/#features', scroll: false },
      { label: 'Pricing', href: '/#pricing', scroll: false },
    ],
  },
  {
    title: 'Developers',
    links: [
      { label: 'Developer resources', href: '/developers' },
      { label: 'Clients and libraries', href: '/developers/#libs', scroll: false },
      { label: 'API reference', href: '/developers/#reference', scroll: false },
      { label: 'Implementation examples', href: '/developers/#examples', scroll: false },
      { label: 'Services status', href: 'https://placekit.statuspage.io', target: '_blank' },
    ],
  },
  {
    title: 'Terms',
    links: [
      { label: 'CGV (FR)', href: '/terms' },
      { label: 'Terms of Service (EN)', href: '/terms/en' },
      { label: 'Privacy Policy', href: '/terms/privacy' },
      { label: 'Fair Usage Policy', href: '/terms/fup' },
      { label: 'Cookies Policy', href: '/terms/cookies' },
      { label: 'Coverage', href: '/terms/coverage' },
    ],
  },
  {
    title: 'Get started',
    links: [
      { label: 'Missing Algolia Places?', href: '/lp/algolia-places' },
      // { label: 'PlaceKit vs. Google Places', href: '/lp/google-places' },
      { label: 'Register', href: 'https://app.placekit.io/auth/register' },
      { label: 'Sign in', href: 'https://app.placekit.io/auth/signin' },
    ],
  },
  {
    title: 'Resources',
    links: [
      { label: 'Blog', href: '/blog' },
      { label: 'About', href: '/about' },
      { label: 'Contact', href: '/about#contact', scroll: false },
    ],
  },
];

const Footer = () => (
  <footer className="flex-0 pt-16 pb-8 border-t border-gray-700 bg-gray-900">
    <Container className="gap-8 columns-1 sm:columns-2 md:columns-4">
      <div className="pb-8 space-y-6 text-gray-400 break-inside-avoid md:break-after-column">
        <Logo size="medium" full={true} />
        <div className="flex items-center gap-4">
          {[
            { name: 'ProductHunt', url: 'https://www.producthunt.com/products/placekit' },
            { name: 'Twitter', url: 'https://twitter.com/placekitapi' },
            { name: 'LinkedIn', url: 'https://linkedin.com/company/placekit' },
            { name: 'GitHub', url: 'https://github.com/placekit' },
          ].map(({ name, url }) => (
            <a
              key={name}
              href={url}
              target="_blank"
              title={name}
              className="text-gray-200 hover:text-gray-50 focus:text-gray-50"
              rel="noreferrer"
            >
              <SocialIcon name={name.toLocaleLowerCase()} branded={false} size="medium" />
            </a>
          ))}
        </div>
        <p className="text-sm">
          © 2022 PlaceKit, all rights reserved.
          <br />
          Made with ♥ in Paris, France.
        </p>
      </div>
      {sections.map(({ title, links }) => (
        <nav key={title} className="pb-8 space-y-2 break-inside-avoid">
          <p className="font-semibold text-gray-400">{title}</p>
          <ul className="space-y-2">
            {links.map(({ label, ...linkProps }) => (
              <li key={label}>
                <Link
                  {...linkProps}
                  className="text-gray-300 hover:text-gray-50 focus:text-gray-50 hover:underline focus:underline underline-offset-2 decoration-2 decoration-gray-600"
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      ))}
    </Container>
  </footer>
);

export default Footer;
