import PropTypes from 'prop-types';
import Script from 'next/script';

import '@placekit/uikit/styles/global.css';

import MainShell from 'components/MainShell';
import { UIProvider } from 'features/UIContext';
import { useSmoothScroll } from 'features/useSmoothScroll';

const App = ({ Component, pageProps }) => {
  useSmoothScroll();

  return (
    <>
      <UIProvider>
        <MainShell meta={pageProps.meta} nav={pageProps.nav}>
          <Component {...pageProps} />
        </MainShell>
      </UIProvider>
      <Script
        id="hs-script-loader"
        src="https://js-eu1.hs-scripts.com/27188519.js"
        strategy="afterInteractive"
      />
    </>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
};

export default App;
