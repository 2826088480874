import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useSmoothScroll = () => {
  const router = useRouter();

  useEffect(() => {
    const html = document.documentElement;
    let scrollTimeout;

    const onHashChangeStart = (url) => {
      if (url.split('#')[0] === router.pathname) {
        html.classList.add('scroll-smooth');
      }
    };
    const onHashChangeComplete = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        html.classList.remove('scroll-smooth');
      }, 100);
    };

    router.events.on('hashChangeStart', onHashChangeStart);
    router.events.on('routeChangeStart', onHashChangeStart);
    router.events.on('hashChangeComplete', onHashChangeComplete);
    router.events.on('routeChangeComplete', onHashChangeComplete);

    return () => {
      router.events.off('hashChangeStart', onHashChangeStart);
      router.events.off('routeChangeStart', onHashChangeStart);
      router.events.off('hashChangeComplete', onHashChangeComplete);
      router.events.off('routeChangeComplete', onHashChangeComplete);
    };
  }, [router]);
};
