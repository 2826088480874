import cx from 'clsx';
import PropTypes from 'prop-types';

const Container = (props) => (
  <div id={props.id} className={cx('max-w-7xl mx-auto px-4 sm:px-6 lg:px-8', props.className)}>
    {props.children}
  </div>
);

Container.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Container;
